import type { AriaDatapointType, DatapointStatus } from 'owa-analytics-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getItem, setItem } from 'owa-local-storage';

const DatapointHistoryMaxLengthStorageKey = 'DatapointTracingMaxHistoryLength';
const DefaultDatapointHistoryMaxLength = 1000;

// Store value in memory so we don't query localstorage every time we push a datapoint
let DatapointHistoryMaxLength: number | undefined = undefined;

type DatapointInfo = {
    datapoint: AriaDatapointType;
    status: DatapointStatus | undefined;
};

const datapointInfo: DatapointInfo[] = [];

export function logToTracingPanel(datapoint: AriaDatapointType, status?: DatapointStatus) {
    if (
        isFeatureEnabled(
            'fwk-diag-datapoint-tracing-panel',
            undefined /* mailboxInfo */,
            true /* dontThrowErrorIfNotInitialized */
        )
    ) {
        if (datapointInfo.length > getDatapointHistoryMaxLength()) {
            setTimeout(() => {
                datapointInfo.splice(0, getDatapointHistoryMaxLength() / 2);
            }, 0);
        }

        datapointInfo.push({ datapoint, status });
    }
}

export function getDatapointInfo() {
    return datapointInfo;
}

export function getDatapointHistoryMaxLength() {
    if (DatapointHistoryMaxLength != undefined) {
        return DatapointHistoryMaxLength;
    }

    return (DatapointHistoryMaxLength =
        parseInt(getItem(self, DatapointHistoryMaxLengthStorageKey) || '') ||
        DefaultDatapointHistoryMaxLength);
}

export function onHistoryMaxLengthChanged(_event: any, newValue: string | undefined) {
    const parsedValue = parseInt(newValue || '');

    if (!isNaN(parsedValue) && parsedValue > 0) {
        setItem(self, DatapointHistoryMaxLengthStorageKey, parsedValue.toString());
        DatapointHistoryMaxLength = parsedValue;
    }
}
